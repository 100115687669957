<script lang="ts" setup>

import { getCategoryRoute, getTranslatedProperty } from "@shopware-pwa/helpers-next";
import { useHeader } from "~/composables/useHeader";
import { integer } from "vscode-languageserver-types";
const { formatLink } = useInternationalization();

const { isMobileDrawerOpened } = useHeader();

const props = withDefaults(
    defineProps<{
      ismobile: boolean;
      navigationElements?: [] | undefined;
      navigationWrapSize?: integer;
      mobileSize?: "large" | "default";
    }>(),
    {
      ismobile: false,
      navigationElements: undefined,
      navigationWrapSize: 10,
      mobileSize: "default",
    }
);

const { data: maasStoresList } = await useMaasStoresList();

const stores = computed(() => {
  return maasStoresList?.value?.stores ? maasStoresList.value.stores : [];
});

const { data: maasCatalogList } = await useMaasCatalogList();

const catalogs = computed(() => {
  return maasCatalogList?.value?.catalogs ? maasCatalogList.value.catalogs : [];
});

const mappedNavigationElements = computed(() => {
  return props.navigationElements.filter(navigationElement => {
    if(navigationElement.type === 'folder' && navigationElement.children.length === 0){
      return false;
    }

    return true;
  }).map(navigationElement => {
    return navigationElement
  })
})
</script>

<template>
  <ul class="flex flex-col lg:flex-row 2xl:gap-2 lg:items-center select-none">
    <li v-for="navigationElement in mappedNavigationElements" :key="navigationElement.id" class="block">
      <component
          :is="(ismobile || navigationElement.type === 'folder') ? 'span' : 'router-link' "
          :target="navigationElement.externalLink || navigationElement.linkNewTab ? '_blank' : undefined"
          :to="!ismobile ? getCategoryRoute(navigationElement) : undefined"
          class="group/link color-maas-typography-navigation-dark peer block flex gap-2 px-4 lg:py-4 [&.active]:bottom-full 3xl:tracking-wider"
          :class="{
            '[&.active]:text-2xl [&.active]:items-center [&.active]:border-b-px [&.active]:sticky [&.active]:top-0  [&.active]:bg-maas-background-default [&.active]:z-1 [&.active]:border-maas-border-grey-light [&.active]:mb-4 [&.active]:pb-4': isMobileDrawerOpened,
            'py-3 text-3xl': isMobileDrawerOpened && mobileSize === 'large',
            'py-2': isMobileDrawerOpened && mobileSize === 'default',
          }"
          data-level="0"
          :data-id="navigationElement.id"
      >
        <svg aria-hidden="true" class="!lg:hidden -z-1 -ml-2 hidden group-[.active]/link:block" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="m15.396 3.146.708.708L7.957 12l8.147 8.146-.707.708L6.543 12l8.854-8.854Z"></path>
        </svg>
        <span
            :class="{
              // 'text-maas-typography-navigation-red': getTranslatedProperty(navigationElement, 'name').includes('%')
            }"
        >{{ getTranslatedProperty(navigationElement, "name") }}</span>
      </component>

      <!-- Flyout on hover !-->
      <div
          v-if="navigationElement.children.length > 0"
          class="z-2 left-full top-0 hidden w-full group-[.alwaysshowbackground]/header:bg-white group-[.showbackground]/header:bg-white peer-[.active]:block lg:absolute lg:left-0 lg:top-full lg:px-12 xl:px-10 3xl:px-16 lg:py-8"
      >
        <!-- backdrop -->
        <div class="border-t-1 absolute left-1/2 top-0 hidden h-full w-screen translate-x-[-50%] bg-white lg:block"></div>

        <div class="lg:flex lg:justify-between">
          <ul class="2xl:ml-[220px] relative gap-16 lg:columns-4 lg:w-fit lg:mb-[40px]">
            <template v-if="navigationElement.internalLink || navigationElement.externalLink || navigationElement.seoUrls">
              <li class="block lg:hidden">
                <NuxtLink
                  :target="navigationElement.externalLink || navigationElement.linkNewTab ? '_blank' : ''"
                  :to="navigationElement?.customFields?.seoUrl ? formatLink('/' + navigationElement.customFields?.seoUrl) : formatLink(getCategoryRoute(navigationElement))"
                  class="block px-4 py-2 lg:p-0 text-2xl"
                >
                  Zur Übersicht
                </NuxtLink>
              </li>
            </template>

            <template
              v-for="(childElement, index) in navigationElement.children"
              v-if="true"
              :key="childElement.id"
            >
              <li
                :class="{
                  'lg:mt-8': true,
                  'lg:[&:first-child]:mt-0': !(navigationElement.internalLink && navigationElement.externalLink && navigationElement.seoUrls),
                  'lg:[&:nth-child(2)]:mt-0': navigationElement.internalLink || navigationElement.externalLink || navigationElement.seoUrls,
                  'lg:break-inside-avoid-column': childElement.children.length <= navigationWrapSize || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length <= navigationWrapSize) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length <= navigationWrapSize),
                  'lg:relative lg:top-[40px]': (childElement.children.length > navigationWrapSize || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > navigationWrapSize) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > navigationWrapSize)),
                }"
              >
                <!-- not clickable for desktop [&.active]: -->
                <span
                    :class="{
                            'group peer flex px-4 py-3 lg:py-0 lg:block lg:p-0 lg:font-bold cursor-pointer lg:cursor-auto': true,
                            'text-2xl': isMobileDrawerOpened,
                            'lg:relative lg:mt-[-40px]': (childElement.children.length > navigationWrapSize || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > navigationWrapSize) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > navigationWrapSize)),
                          }"
                    data-level="1"
                    :data-id="childElement.id"
                >
                  <template v-if="childElement.internalLink || childElement.externalLink || childElement.seoUrls">
                    <!-- only show this "group" title as link for desktop version or for mobile version, if the item has no submenu items -->
                    <NuxtLink
                        :target="childElement.externalLink || childElement.linkNewTab ? '_blank' : ''"
                        :to="childElement.customFields?.seoUrl ? formatLink('/' + childElement.customFields?.seoUrl) : formatLink(getCategoryRoute(childElement))"
                        class="lg:block lg:hover:underline lg:hover:underline-offset-3"
                        :class="{
                          'hidden': (childElement.children.length > 0 || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > 1) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > 1)),
                          'block': !(childElement.children.length > 0 || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > 1) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > 1)),
                          'text-maas-typography-navigation-red': getTranslatedProperty(
                            childElement,
                            'name',
                          ).includes('%'),
                        }"
                    >
                      {{ getTranslatedProperty(childElement, "name") }}
                    </NuxtLink>
                    <!-- provide this "group" title as non-link for mobile version, if the item has no submenu items -->
                    <span
                      class="block lg:hidden"
                      :class="{
                        'hidden': childElement.children.length === 0 && !(getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > 1) && !(getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > 1)
                      }"
                    >
                      {{ getTranslatedProperty(childElement, "name") }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      :class="{
                        'text-maas-typography-navigation-red': getTranslatedProperty(childElement, 'name').includes('%'),
                      }"
                    >
                      {{ getTranslatedProperty(childElement, "name") }}
                    </span>
                  </template>

                  <span
                    v-if="childElement.children.length > 0 || (getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > 1) || (getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length > 1)"
                    class="relative ml-auto block w-[24px] lg:hidden"
                  >
                    <svg
                        class="absolute block group-[.active]:hidden"
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M12 5v14"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      ></path>
                    </svg>
                    <svg
                        class="absolute"
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M5 12h14"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      ></path>
                    </svg>
                  </span>
                </span>

                <!-- is collapsable mobile -->
                <!-- for desktop this should be grid with optional 2 cols -->
                <ul v-if="childElement.children.length > 0" class="ml-1 mb-2 lg:mb-0 lg:ml-0 hidden flex-col peer-[.active]:flex lg:mt-4 break-before-none lg:block">
                  <!-- only visible for mobile -->
                  <template v-if="childElement.children.length > 0 && (childElement.internalLink || childElement.externalLink || childElement.seoUrls)">
                    <li class="block lg:hidden">
                      <NuxtLink
                          :target="childElement.externalLink || childElement.linkNewTab ? '_blank' : ''"
                          :to="childElement.customFields?.seoUrl ? formatLink('/' + childElement.customFields?.seoUrl) : formatLink(getCategoryRoute(childElement))"
                          class="block px-4 py-2 lg:p-0"
                      >
                        Zur Übersicht
                      </NuxtLink>
                    </li>
                  </template>

                  <template
                      v-for="(subchildElement, subchildindex) in childElement.children"
                      :key="subchildElement.id"
                  >
                    <li class="lg:py-1 lg:min-w-[190px] lg:max-w-[190px]">
                      <template
                        v-if="subchildElement.internalLink || subchildElement.externalLink || subchildElement.seoUrls"
                      >
                        <NuxtLink
                            :class="{
                              'block px-4 py-2 lg:p-0 lg:hover:underline lg:hover:underline-offset-3': true,
                              'text-maas-typography-navigation-red': getTranslatedProperty(
                                subchildElement,
                                'name',
                              ).includes('%'),
                            }"
                            :target="subchildElement.externalLink || subchildElement.linkNewTab ? '_blank' : ''"
                            :to="subchildElement.customFields?.seoUrl ? formatLink('/' + subchildElement.customFields?.seoUrl) : formatLink(getCategoryRoute(subchildElement))"
                        >
                          {{ getTranslatedProperty(subchildElement, "name") }}
                        </NuxtLink>

                        <ul
                          v-for="(level4, level4index) in subchildElement.children"
                          :key="level4.id"
                          class="block lg:hidden"
                        >
                          <li>
                            <NuxtLink
                              :class="{
                              'flex gap-2 px-4 py-2 lg:p-0 lg:hover:underline lg:hover:underline-offset-3': true,
                            }"
                              :target="level4.externalLink || level4.linkNewTab ? '_blank' : ''"
                              :to="level4.customFields?.seoUrl ? formatLink('/' + level4.customFields?.seoUrl) : formatLink(getCategoryRoute(level4))"
                            >
                              <span class="shrink-0 grow-0">–</span>
                              <span>{{ getTranslatedProperty(level4, "name") }}</span>
                            </NuxtLink>
                          </li>
                        </ul>
                      </template>
                    </li>
                  </template>
                </ul>

                <template v-if="getTranslatedProperty(childElement, 'name') === 'Standorte' && stores.length > 1">
                  <!-- Stores-->
                  <ul class="ml-1 mb-2 lg:mb-0 lg:ml-0 hidden flex-col peer-[.active]:flex lg:mt-4 break-before-none lg:block">
                      <li
                        v-for="store in stores"
                        :key="store.id"
                        class="lg:py-1 lg:min-w-[190px] lg:max-w-[190px]"
                      >
                        <NuxtLink
                          :to="formatLink('/Filialen/Standorte/' + store.slug)"
                          class="block px-4 py-2 lg:p-0 lg:hover:underline lg:hover:underline-offset-3"
                        >
                          {{ store.city }} <template v-if="store.type === 'Outlet'">Outlet</template>
                        </NuxtLink>
                      </li>
                  </ul>
                </template>

                <template v-if="getTranslatedProperty(childElement, 'name') === 'Kataloge' && catalogs.length >= 1">
                  <!-- Catalogs-->
                  <ul class="ml-1 mb-2 lg:mb-0 lg:ml-0 hidden flex-col peer-[.active]:flex lg:mt-4 break-before-none lg:block">
                      <li
                        v-for="catalog in catalogs"
                        :key="catalog.id"
                        class="lg:py-1 lg:min-w-[190px] lg:max-w-[190px]"
                      >
                        <NuxtLink
                          :to="formatLink('/katalog/' + catalog.slug)"
                          class="block px-4 py-2 lg:p-0 lg:hover:underline lg:hover:underline-offset-3"
                        >
                          {{ catalog.title }}
                        </NuxtLink>
                      </li>
                  </ul>
                </template>
              </li>
            </template>
          </ul>
          <div class="hidden lg:flex z-1 gap-6 items-start">
            <NuxtLink
                v-for="teaser in navigationElement?.customFields['category-teaser']"
                :to="formatLink(teaser?.url)"
                class="hidden xl:first:block 3xl:block relative aspect-2/3 group overflow-hidden"
            >
              <SharedCmsImage
                  :src="teaser?.media?.url"
                  :crop="teaser?.media?.crop"
                  :focus-point="teaser?.media?.focusPoint"
                  :alt="teaser?.title"
                  width="280"
                  height="auto"
                  class-img="min-w-70 max-w-70 aspect-2/3 block scale-100 transition-all duration-500 group-hover:scale-105"
              />
              <div class="absolute left-0 top-0 p-2.5">
                <span
                    v-if="teaser?.icon === 'sale'"
                    class="bg-maas-icon-primary flex h-8 w-8 items-center justify-center rounded-full text-p text-maas-typography-text-light"
                    aria-label="Sale Artikel"
                >
                  %
                </span>
                <span
                    v-if="teaser?.icon === 'new'"
                    class="bg-maas-icon-primary flex h-8 w-8 items-center justify-center rounded-full text-small text-maas-typography-text-light"
                    aria-label="New Artikel"
                >
                  NEU
                </span>
              </div>
              <div
                  v-if="teaser?.title || teaser?.link"
                  class="absolute bottom-0 w-full py-2 px-4"
                  :class="{
                    'bg-maas-background-grey-light': teaser?.color === 'default',
                    'bg-maas-background-primary text-maas-typography-text-light': teaser?.color === 'highlight',
                  }"
              >
                <h5 class="text-h6">{{ teaser?.title }}</h5>
                <p class="text-small underline underline-offset-3 group-hover:decoration-none">{{ teaser?.link }}</p>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
